:root {
  /* common */
  --brand-color: #6264a7;
  --button-color: #6264a7;
  --button-text-color: #fff;
  --button-hover-color: rgb(88, 90, 150);
  --button-hover-text-color: #fff;
  --button-active-color: rgb(70, 71, 117);
  --button-active-text-color: #fff;
  --button-border: 1px solid hsla(0, 0%, 100%, 0.04);
  --button-shadow: rgb(0 0 0 / 25%) 0px 0.2rem 0.4rem -0.075rem;
  --button2-color: #fff;
  --button2-text-color: rgb(37, 36, 35);
  --button2-hover-color: rgb(237, 235, 233);
  --button2-active-color: rgb(225, 223, 221);
  --button2-border: 1px solid rgb(225, 223, 221);
  --button2-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
  --button-disabled-color: rgb(237, 235, 233);
  --button-disabled-text-color: rgb(200, 198, 196);
  --input-background-color: rgb(243, 242, 241);
  --input-border-color: transparent;
  --input-border-width: 0 0 0.1429rem 0;
  --input-focus-border-color: transparent;
  --input-focus-border-bottom-color: #6264a7;
  --table-color: transparent;
  --table-border: 1px solid rgb(237, 235, 233);
  --border-color: rgb(237, 235, 233);


  /* light theme */
  --font-color: #252423;
  --background-color: #fff;
  --link-color: #6264a7;
  --border-color: #e1dfdd;
  --warning-color: #c4314b;
  --modal-background-color: #fafafa;
  --second-layer-background-color: #fafafa;
  --tcf-detailsmodal-tagging-backgound-color: #fafafa;
  --easy-search-result-card-background-color: #efefef;

  .dark,
  .DARK {
    --font-color: #fff;
    --background-color: #transparent;
    --link-color: #a6a7dc;
    --border-color: #605e5c;
    --warning-color: #f9526b;
    --modal-background-color: #121212;
    --second-layer-background-color: #121212;
    --tcf-detailsmodal-tagging-backgound-color: #121212;
    --easy-search-result-card-background-color: #605e5c;
  }

  .contrast {
    --brand-color: #ffff01;
    --font-color: #fff;
    --link-color: #ffff01;
    --background-color: transparent;
    --border-color: #fff;
    --button-color: transparent;
    --button-text-color: #fff;
    --button-hover-color: #ffff01;
    --button-hover-text-color: #000;
    --button-active-color: #1aebff;
    --button-active-text-color: #000;
    --button-border: 0.2rem solid #fff;
    --input-background-color: transparent;
    --input-border-color: #fff;
    --input-border-width: 1px;
    --input-focus-border-color: #1aebff;
    --input-focus-border-bottom-color: #1aebff;
    --warning-color: #ffff01;
    --modal-background-color: #000;
    --second-layer-background-color: #000;
    --tcf-detailsmodal-tagging-backgound-color: #000;
  }
}

// @import url(variables.scss);
//$bg-img: url('assets/images/bg_circle.svg');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body,
html {
  font-family: 'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden; // Body and HTML should never overflow.
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1
}

// Correct and professional way to overflow the content, because child-elements are inside root and not the body itself.
#root {
  overflow: auto;
}

.fui-FluentProviderr1 {
  font-family: 'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif;
}

// Optional: Apply it specifically for Fluent UI's <Text /> components if needed
.fui-Text {
  font-family: 'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  z-index: 1;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  z-index: 1;
}

[class^='sui'] {
  font-family: 'Inter', 'BlinkMacSystemFont', Helvetica, Arial, sans-serif;
  color: black;
}

.sui-layout-main-header__inner,
.sui-sorting__label,
.sui-facet-view-more,
.sui-facet__title,
.sui-multi-checkbox-facet__option-count,
.sui-multi-checkbox-facet__input-text {
  color: var(--colorNeutralForeground2);
}

.sui-facet-search__text-input {
  padding: 8px;
  background-color: var(--modal-background-color);
  color: var(--colorNeutralForeground2);
}

.sui-search-box__text-input {
  border-radius: 6px;
  color: var(--colorNeutralForeground2);
  background-color: var(--modal-background-color);
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 99999;
}

#root,
.euiThemeProvider {
  height: 100%;
}

.easy-background {
  background-size: cover;
  background-attachment: fixed;
  background-image: url('images/background_element_1.svg');
  background-repeat: no-repeat;
  margin: 0px;
  padding: 0px;
}

.easy-details-modal {
  min-width: 500px;
}

.tcf-welcome-cards {
  margin-left: 30%;
  width: 700px;
}

.tcf-configuration {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
  height: 100%;
  width: 100%;
}

.tcf-page,
.tcf-transparent-background {
  background: transparent !important;
}

.tcf-header {
  margin: 20px;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 10px;
}

.sui-layout-header {
  border-bottom: none;
}

.sui-layout-main-header__inner {
  gap: 1rem;
}

.sui-layout-body {
  background: none !important;
}

.sui-facet-search__text-input {
  height: auto;
}

.sui-layout-main {
  width: 100%;
  padding-top: 0px !important;
}

.sui-layout-main-header {
  margin-bottom: 15px;
}

.sui-results-container,
.ed-view-standard>.sui-results-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fill,
      minmax(300px, 1fr)); // Adjust min width for responsiveness
  gap: 1.5rem; // Space between each card
  position: relative;
}

.ed-view-presence>.sui-results-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1.5rem;
  position: relative;
}

.sui-result {
  font-family: inherit;
  border-radius: 10px;
  box-shadow:
    0 0.9px 4px rgba(0, 0, 0, 0.08),
    0 2.6px 8px rgba(0, 0, 0, 0.06),
    0 5.7px 12px rgba(0, 0, 0, 0.05),
    0 15px 15px rgba(0, 0, 0, 0.04);
  height: 100%;
}

.euiFieldText,
.euiFieldText:focus,
.euiComboBox__inputWrap,
.euiComboBox .euiComboBox__input,
.sui-search-box__autocomplete-container,
.sui-select__value-container,
.sui-select__menu,
.sui-select__control,
.sui-results-per-page .sui-select__control {
  background-color: var(--modal-background-color) !important;
  color: var(--colorNeutralForeground2) !important;
}

.easy-details-modal-fluentProvider {
  background-color: transparent !important;
}

.easy-details-modal-footer {
  padding-block: 16px 14px !important;
}

.easy-details-modal-footer .css-1vhepo5-euiButtonDisplay-euiButtonEmpty-s-empty-primary {
  block-size: 24px;
}

.css-sec0wf-euiCard__footer,
.easy-details-modal-footer {
  flex-grow: 0;
  inline-size: 100%;
  margin-block-start: 16px;
  padding: 8px 20px;
  background: linear-gradient(180deg, var(--easy-search-result-card-background-color) 0%, var(--background-color) 40%);
}

.easy-searchResult-card .css-jolp8x-euiCard__content-vertical {
  padding: 20px;
}

.sui-select__single-value {
  color: var(--colorNeutralForeground2) !important;
}

.sui-result-standard {
  @extend .sui-result;
  background-color: var(--modal-background-color);
  max-width: 400px; // Adjust this value to your preferred width
  margin: 0 auto; // Centers the card within its container
  padding: 20px; // Adjust padding as needed for consistent spacing
  border-radius: 8px; // Optional: rounds corners slightly for a card-like appearance
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional: subtle shadow for depth
}

.sui-result-presence {
  @extend .sui-result;
  background-color: var(--modal-background-color);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.sui-result * {
  min-width: 0;
}

.sui-result+.sui-result {
  margin-top: inherit;
}

.sui-result__header {
  padding-left: 0px;
  padding-right: 0px;
  justify-content: left;
  gap: 1rem;
}

.sui-result__header .fui-Avatar {
  border-radius: 50px !important;
}

.sui-result__header .action-container {
  gap: 8px;
}

.sui-result__header-center {
  gap: 4px;
}

.sui-result__body {
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;
  max-width: 100%;
  width: 100%;
}

.sui-result__body-wrapper {
  position: relative;
  width: 100%;
}

.sui-result__body .euiDescriptionList {
  //color: #999;
  margin-top: 15px;
}

.sui-layout-body__inner {
  max-width: none !important;
  padding-left: 30px;
  padding-right: 20px;
}

.sui-layout-body:after {
  background-color: transparent !important;
  height: 0px;
}

.sui-layout-main-footer {
  margin-top: 50px;
}

.sui-search-box__submit {
  background: rgb(93, 91, 212);
  background: linear-gradient(22deg,
      rgba(93, 91, 212, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%);
  color: rgb(93, 91, 212);
}

.sui-search-box__submit:hover {
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 0px 0px 1px inset,
    rgba(59, 69, 79, 0.3) 0px 2px 4px;
  background: linear-gradient(rgb(93, 91, 212), rgb(93, 91, 212)) rgba(93, 91, 212, 0.2);
  color: #fff;
}

.tcf-cursor-hand {
  cursor: pointer;
}

.tcf-cursor-hand:hover {
  color: rgb(93, 91, 212);
}

.tcf-align-right {
  float: right;
  margin-top: 10px;
}

.css-53k5cm-euiPageSection__content-l {
  padding-block: 0px !important;
}

.entraid {
  border-left-color: orange;
  border-left-width: 5px;
}

.personal {
  border-left-color: rgb(93, 91, 212);
  border-left-width: 5px;
}

.standardView {
  min-height: 0;
}

.presenceView {
  min-height: 0px;
}

.sui-layout-sidebar {
  padding-top: 0px;
  padding-right: 5px;
}

.sui-layout-sidebar-presenceView {
  display: none;
}

.sui-layout-sidebar-standardView {
  width: 280px;
  padding: 32px 32px 0 0;
}

.sui-layout-sidebar {
  width: auto;
  padding: auto;
}

.clickable:hover {
  cursor: pointer;
}

.clickable:hover {
  text-decoration: underline;
}

.card-away,
.card-berightback {
  background-image: linear-gradient(to left top,
      rgba(255, 170, 68, 0.2),
      transparent);
  border: 3px solid #fa4 !important;
}

.card-busy,
.card-busyidle,
.card-donotdisturb {
  background-image: linear-gradient(to left top,
      rgba(197, 15, 31, 0.2),
      transparent);
  border: 3px solid #c50f1f !important;
}

.card-available,
.card-availableidle {
  background-image: linear-gradient(to left top,
      rgba(107, 183, 0, 0.2),
      transparent);
  border: 3px solid #6bb700 !important;
}

.card-offline {
  background-image: linear-gradient(to left top,
      rgba(138, 136, 134, 0.2),
      transparent);
  border: 3px solid #8a8886 !important;
}

.card-out-of-office {
  background-image: linear-gradient(to left top,
      rgba(180, 0, 158, 0.2),
      transparent);
  border: 3px solid #b4009e !important;
}

.card-presenceunknown {
  background-image: linear-gradient(to left top,
      rgba(188, 195, 199, 255),
      transparent);
  border: 3px solid rgba(188, 195, 199, 255) !important;
}

.sui-multi-checkbox-facet__option-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
}

.sui-multi-checkbox-facet__option-input-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
}

.sui-multi-checkbox-facet__input-text {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sui-multi-checkbox-facet__option-count {
  margin-left: 8px;
  /* Space between text and count */
  flex-shrink: 0;
  /* Prevents count from shrinking */
}

.easy-modal-no-header {
  padding-block-start: 1.5rem;
  padding-block-end: 1.5rem;
}

.easy-contact-description,
.easy-contact-description__text {
  color: #666;
}

.easy-contact-description__text {
  font-weight: 500;
}

.easy-modal__body {

  .sui-result__header,
  .sui-result__body {
    color: inherit;
  }
}

.sui-multi-checkbox-facet__option-input-wrapper span.euiToolTipAnchor {
  display: grid;
}

// div.main-filter,
// .sui-sorting.main-filter {
//   border: 3px solid #2f69a4;
//   padding: 10px;
//   border-radius: 5px;
//   margin-bottom: 20px;

//   // hides if has no child elements
//   &:not(:has(*)) {
//     display: none;
//   }
// }

.easy-welcome-cards,
.easy-welcome-help-cards {
  max-width: 80%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.easy-welcome-card {
  max-width: 400px;
  min-width: 350px;
  height: 100%;
}

.easy-welcome-cards .euiTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.easy-welcome-card .euiCard__content {
  min-height: 80px;
}

.easy-welcome-help-cards .easy-welcome-help-card {
  min-width: 50%;
}

.easy-welcome-cards .easy-welcome-card,
.easy-welcome-help-cards .easy-welcome-help-card {
  cursor: pointer;
}

.euiFormLabel.euiFormLabel-isFocused {
  color: var(--colorNeutralForeground2) !important;
}

.easy-view-selector {
  min-width: 200px !important;
}

@media screen and (min-width: 1150px) {
  .easy-view-selector {
    min-width: 350px !important;
  }
}

button.euiCard__titleButton,
.css-g3povl-euiCard__text-left-interactive,
.css-q4on12-euiCard__text-left {
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none !important;
  font-size: 18px !important;
}

button.euiCard__titleButton:hover {
  text-decoration: none !important;
}

.easy-result-card-subtitle {
  color: #8a8886 !important;
}

.dot {
  height: 10px;
  width: 10px;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
}

.dark {
  color: #fff !important;

  .css-sec0wf-euiCard__footer,
  .easy-details-modal-footer {
    flex-grow: 0;
    inline-size: 100%;
    margin-block-start: 16px;
    padding: 8px 20px;
    background: linear-gradient(180deg, var(--background-color) 0%, var(--background-color) 40%);
  }

  .fui-FluentProvider {
    background-color: var(--background-color);
  }
}


.fui-DialogSurface,
.r1svjbtt,
.fui-DialogSurface.r1svjbtt {
  background-color: var(--modal-background-color);
}


.sui-layout-sidebar-toggle {
  color: var(--colorNeutralForeground2);
  background-color: var(--modal-background-color);
  border: 1px solid var(--colorNeutralForeground);
}

.sui-select__indicators.css-1hb7zxy-IndicatorsContainer {
  color: var(--colorNeutralForeground2);
}

.sui-select__menu,
.sui-select__menu.css-1nmdiq5-menu {
  background-color: var(--modal-background-color);
  color: var(--colorNeutralForeground2);

  p,
  span,
  div {
    color: var(--colorNeutralForeground2);
  }

  .sui-select__option {

    &:hover,
    &--is-focused {
      background-color: var(--hover-background-color);
      color: var(--hover-text-color);
    }
  }
}

.euiFlexGroup>.euiFlexItem>.euiTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  // body {
  //   background-color: red;
  // }

  span.euiLoadingSpinner {
    margin: auto;
  }

  .euiPanel {
    .css-1drqwgc-euiFlexGroup-responsive-l-flexStart-stretch-row {
      flex-wrap: unset;

      &>.euiFlexItem {
        inline-size: unset;
        flex-basis: unset;
      }
    }
  }

  .easy-welcome-card {
    max-width: 100%;
    min-width: unset;
    height: 100%;
  }

  .easy-welcome-cards,
  .easy-welcome-help-cards {
    max-width: 100%;
  }

  .sui-layout-body__inner {
    max-width: none !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sui-layout-sidebar {
    background-color: var(--colorNeutralBackground1);
    width: 100%;
    padding: 32px 24px;
  }

  .sui-layout-sidebar-standardView {
    width: unset;
    padding: unset;
  }

  [class^=sui] {
    color: var(--colorNeutralForeground2);
  }

  .button.sui-layout-sidebar-toggle {
    margin: 20px 0;
  }

  .sui-layout-main-header__inner>div:nth-child(2) {
    display: none;
  }

  header__inner {
    margin-top: 20px;
  }

  .tcf-configuration {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    height: 100%;
    width: 100%;
  }

  .fui-DialogSurface {
    max-height: calc(100vh - 30px) !important;
  }

  .fui-DialogSurface__backdrop.rsptlh5 {
    max-height: calc(100vh - 30px);
  }

  // .euiTableRow,
  // .euiTableCellContent {
  //   flex-direction: row-reverse;
  // }
}


/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  // body {
  //   background-color: yellow;
  // }

  span.euiLoadingSpinner {
    margin: auto;
  }

  .euiPanel {
    .css-1drqwgc-euiFlexGroup-responsive-l-flexStart-stretch-row {
      flex-wrap: unset;

      &>.euiFlexItem {
        inline-size: unset;
        flex-basis: unset;
      }
    }
  }

  .easy-welcome-card {
    max-width: 100%;
    min-width: unset;
    height: 100%;
  }

  .easy-welcome-cards,
  .easy-welcome-help-cards {
    max-width: 100%;
  }

  .sui-layout-body__inner {
    max-width: none !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sui-layout-sidebar {
    background-color: var(--colorNeutralBackground1);
    padding: 32px 24px;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  // body {
  //   background-color: blue;
  // }
}

/* Media Query for Tablets Ipads portrait mode or bigger */
@media (min-width: 768px) {
  .euiFlexGroup>.euiFlexItem:has(> .euiTitle) {
  //  max-width: calc(100% - 70px);
    overflow: hidden;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  //   body {
  //     background-color: green;
  //   }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  // body {
  //   background-color: white;
  // }
}